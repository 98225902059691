import '../../App.css';
import React from "react";
import { ChakraProvider, Text, Box, Heading, Input, Grid, GridItem, Menu, Flex, MenuButton, MenuDivider, MenuItem, MenuList, Button, useToast, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { get, patch } from '../../api';
import { jwtDecode } from 'jwt-decode';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

function Account() {
    const toast = useToast();  
    const getUserIdFromToken = () => {
        const token = localStorage.getItem('refreshToken');
        if (!token) return null;

        try {
            const decoded = jwtDecode(token);
            return decoded.id;
        } catch (error) {
            console.error('Failed to decode token:', error);
            return null;
        }
    };
    
    const userId = getUserIdFromToken();
    const [name, setName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [numberOfEmployees, setNumberOfEmployees] = React.useState('');
    const [industryOptions, setIndustryOptions] = React.useState([]);
    const [selectedOptionIndustry, setSelectedOptionIndustry] = React.useState('Select the option');
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [errors, setErrors] = React.useState({});
    const [show, setShow] = React.useState(false)
    const [showC, setShowC] = React.useState(false)
    const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
    const menuButtonRef = React.useRef();
  
    React.useEffect(() => {
      if (menuButtonRef.current) {
        setMenuButtonWidth(menuButtonRef.current.clientWidth);
      }
    }, [menuButtonRef.current]);
  
    const handleClick = () => setShow(!show)
    const handleClickC = () => setShowC(!showC)
  
    const validate = (field, value) => {
      let error = '';
  
      switch (field) {
        case 'name':
            const nameRegex = /^[a-zA-Z]+$/;
            if (!value) {
              error = 'Name is required';
            } else if (!nameRegex.test(value)) {
              error = 'Name is invalid';
            }
            break;
        case 'phoneNumber':
          const phoneRegex = /^\d{10}$/;
          if (!value) {
            error = 'Phone number is required';
          } else if (!phoneRegex.test(value)) {
            error = 'Phone number is invalid';
          }
          break;
        case 'email':
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!value) {
            error = 'Email is required';
          } else if (!emailRegex.test(value)) {
            error = 'Email is invalid';
          }
          break;
        case 'companyName':
            const companyNameRegex = /^[a-zA-Z0-9\s]{1,50}$/;
            if (!value) {
              error = 'Company name is required';
            } else if (!companyNameRegex.test(value)) {
              error = 'Company name is invalid';
            }
          break;
        case 'numberOfEmployees':
          if (!value) {
            error = 'Number of employees is required';
          } else if (isNaN(value)) {
            error = 'Number of employees must be a number';
          }
          break;
        case 'industryType':
          if (value === 'Select the option') {
            error = 'Industry type is required';
          }
          break;
        case 'password':
          if (value && value.length < 6) {
            error = 'Password must be at least 6 characters';
          }
          break;
        case 'confirmPassword':
          if (value && value !== password) {
            error = 'Confirm password must match the password';
          }
          break;
        default:
          break;
      }
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error,
      }));

      return error;
    };
  
    const handleBlur = (field) => (e) => {
      validate(field, e.target.value);
    };

    const handleChange = (field, value) => {
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'companyName':
                setCompanyName(value);
                break;
            case 'numberOfEmployees':
                setNumberOfEmployees(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
        validate(field, value);
    };
  
    const handleSubmit = async () => {
        const fields = [
            'name',
            'phoneNumber',
            'email',
            'companyName',
            'numberOfEmployees',
            'industryType',
        ];
  
        const validationErrors = {};
  
        fields.forEach((field) => {
            const value = field === 'industryType' ? selectedOptionIndustry : eval(field);
            const error = validate(field, value);
            if (error) {
                validationErrors[field] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
  
        // Perform the submit action
        const data = {
            userName: name,
            phoneNumber: phoneNumber,
            emailId: email,
            companyName: companyName,
            companySize: numberOfEmployees,
            industryType: selectedOptionIndustry,
        };
  
        try {
            const response = await patch(`users/${userId}`, data);
            const userData = response.data.data;
  
            setName(userData.userName);
            setEmail(userData.emailId);
            setPhoneNumber(userData.phoneNumber);
            setCompanyName(userData.companyName);
            setNumberOfEmployees(userData.companySize);
            setSelectedOptionIndustry(userData.industryType);
  
            // Show success toast
            toast({
                title: response.data.message,
                description: 'User has been updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
          
        } catch (error) {
            console.error('Error submitting form:', error);
            toast({
                title: 'Error',
                description: error.response?.data?.message || 'An error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        }
    }

    const handlePasswordChange = async () => {
        const fields = [
            'password',
            'confirmPassword',
        ];
  
        const validationErrors = {};
  
        fields.forEach((field) => {
            const value = eval(field);
            const error = validate(field, value);
            if (error) {
                validationErrors[field] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const data = {
            password: password,
            confirmpassword: confirmPassword
        };
    
        try {
            const response = await patch(`users/${userId}/password`, data);
            setPassword('')
            setConfirmPassword('')
                
            // Show success toast
            toast({
                title: 'Password updated',
                description: response.data.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        
        } catch (error) {
            console.error('Error updating password:', error);
            if (error.response && error.response.data.message === "Password does not match") {
                setErrors({ confirmPassword: 'Password does not match' });
            }
        }
    }
    
    
    React.useEffect(() => {
        const fetchIndustryTypes = async () => {
          try {
            const response = await get('/reportmanagement/industry');
              
            const industryTypes = response.data.data.map(item => ({
              id: item.industryTypeId,
              type: item.industryType,
            }));
            setIndustryOptions(industryTypes);
            // if (industryTypes.length > 0) {
            //   setSelectedOptionIndustry(industryTypes[0].type);
            // }
          } catch (error) {
            console.error('Error fetching industry types:', error);
          }
        };
        
        fetchIndustryTypes();
      }, []);
  
    React.useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await get(`users/${userId}`);
                const userData = response.data.data;
                setName(userData.userName);
                setPhoneNumber(userData.phoneNumber);
                setEmail(userData.emailId);
                setCompanyName(userData.companyName);
                setNumberOfEmployees(userData.companySize);
                setSelectedOptionIndustry(userData.industryType);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
  
        fetchUserDetails();
    }, []);

    const isFormValid = () => {
        return !Object.values(errors).some(error => error) && password && confirmPassword;
    };

  return (
    <ChakraProvider>
        <Box px={[4, 6, 10]} py={10} w="100%" h="fit-content" overflowY="scroll">
            <Box mb={6}>
                <Heading size="md" fontSize="24px" fontWeight="700" mb={2}>
                    Account Details
                </Heading>
                <Text fontSize="18px" fontWeight="500" color="#6C737F">
                    Update your account details here
                </Text>
            </Box>
            <Text fontSize="20px" fontWeight="600" color="black" mb='8px'>
                User Details
            </Text>
            <Box w="100%" bg="#F5F8FF" p="20px" h="fit-content" border="1px solid #D1E0FF" borderRadius="12px" mb={6}>
                <Box>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Name of the user
                                </Text>
                                <Input
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.name ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter your Name"
                                    size="md"
                                    value={name}
                                    onChange={(e) => handleChange("name", e.target.value)}
                                    onBlur={() => handleBlur("name")}
                                    borderColor={errors.name ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {errors.name && <Text color="red.500" fontSize="sm">{errors.name}</Text>}
                            </Box>
                        </GridItem>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Phone Number
                                </Text>
                                <Input
                                    type='number'
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.phoneNumber ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter your phone number"
                                    size="md"
                                    value={phoneNumber}
                                    onChange={(e) => handleChange("phoneNumber", e.target.value)}
                                    onBlur={() => handleBlur("phoneNumber")}
                                    borderColor={errors.phoneNumber ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {errors.phoneNumber && <Text color="red.500" fontSize="sm">{errors.phoneNumber}</Text>}
                            </Box>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6} mt={6}>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Email Address
                                </Text>
                                <Input
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.email ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter your email address"
                                    size="md"
                                    value={email}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                    onBlur={() => handleBlur("email")}
                                    borderColor={errors.email ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
                            </Box>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
            <Text fontSize="20px" fontWeight="600" color="black" mb='8px'>
                Company Details
            </Text>
            <Box w="100%" bg="#F5F8FF" p="20px" h="fit-content" border="1px solid #D1E0FF" borderRadius="12px" mb={6}>
                <Box>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Name of the company
                                </Text>
                                <Input
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.companyName ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter your company name"
                                    size="md"
                                    value={companyName}
                                    onChange={(e) => handleChange("companyName", e.target.value)}
                                    onBlur={() => handleBlur("companyName")}
                                    borderColor={errors.companyName ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {errors.companyName && <Text color="red.500" fontSize="sm">{errors.companyName}</Text>}
                            </Box>
                        </GridItem>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Number of the Employees in company
                                </Text>
                                <Input
                                    type='number'
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.numberOfEmployees ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter size of the company"
                                    size="md"
                                    value={numberOfEmployees}
                                    onChange={(e) => handleChange("numberOfEmployees", e.target.value)}
                                    onBlur={() => handleBlur("numberOfEmployees")}
                                    borderColor={errors.numberOfEmployees ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {errors.numberOfEmployees && <Text color="red.500" fontSize="sm">{errors.numberOfEmployees}</Text>}
                            </Box>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6} mt={6}>
                        {/* <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Select the industry type
                                </Text>
                                <Menu>
                                    {({ isOpen }) => (
                                        <>
                                            <MenuButton
                                                ref={menuButtonRef}
                                                w="100%"
                                                bg="white"
                                                border="1px solid #F3F4F6"
                                                borderColor={errors.industryType ? "red.500" : "#F3F4F6"}
                                                fontWeight="400"
                                                _hover={{ bg: "white", borderColor: errors.industryType ? "red.500" : "#D1E0FF" }}
                                                _active={{ bg: "white", borderColor: errors.industryType ? "red.500" : "#D1E0FF" }}
                                                _focus={{ bg: "white", borderColor: errors.industryType ? "red.500" : "#D1E0FF" }}
                                                as={Button}
                                                textAlign="left"
                                                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                            >
                                                {selectedOptionIndustry}
                                            </MenuButton>
                                            <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor="#EFF4FF" borderRadius="12px" maxHeight="400px" overflowY="auto">
                                                {industryOptions.map((option, index) => (
                                                    <React.Fragment key={index}>
                                                        <MenuItem
                                                            onClick={() => {
                                                                setSelectedOptionIndustry(option.type);
                                                                validate('industryType', option.type);
                                                            }}
                                                            onBlur={() => handleBlur("industryType")}
                                                            borderRadius="5px"
                                                            bg={selectedOptionIndustry === option.type ? "#EFF4FF" : "white"}
                                                            _hover={{ bg: "#EFF4FF" }}
                                                            color="#00395E"
                                                            textAlign="left"
                                                        >
                                                            <Flex justify="space-between" align="center" w="100%">
                                                                <Box>{option.type}</Box>
                                                                {selectedOptionIndustry === option.type && (
                                                                    <Box as="span" color="#00359E" ml={2}>
                                                                        <CheckIcon boxSize={3} />
                                                                    </Box>
                                                                )}
                                                            </Flex>
                                                        </MenuItem>
                                                        {index < industryOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                                                    </React.Fragment>
                                                ))}
                                            </MenuList>
                                        </>
                                    )}
                                </Menu>
                                {errors.industryType && <Text color="red.500" fontSize="sm">{errors.industryType}</Text>}
                            </Box>
                        </GridItem> */}
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Industry type
                                </Text>
                                <Input
                                    variant="outline"
                                    border="1px solid #F3F4F6"
                                    focusBorderColor={errors.numberOfEmployees ? "red.500" : "#D1E0FF"}
                                    bg="white"
                                    placeholder="Please enter size of the company"
                                    size="md"
                                    value={selectedOptionIndustry}
                                    // onChange={(e) => handleChange("numberOfEmployees", e.target.value)}
                                    // onBlur={() => handleBlur("numberOfEmployees")}
                                    // borderColor={errors.numberOfEmployees ? "red.500" : "#F3F4F6"}
                                    isReadOnly
                                    sx={{
                                        '::placeholder': {
                                            color: '#9DA4AE',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'auto',
                                    }}
                                />
                                {/* {errors.numberOfEmployees && <Text color="red.500" fontSize="sm">{errors.numberOfEmployees}</Text>} */}
                            </Box>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
            {/* <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%" mb={6}>
                <Button
                    bg="#2970FF"
                    color="white"
                    variant="solid"
                    width={{ base: "100%", md: "18%" }}
                    _hover={{ bg: "#7044c4" }}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box> */}
            <Text fontSize="20px" fontWeight="600" color="black" mb='8px'>
                Change Password
            </Text>
            <Box w="100%" bg="#F5F8FF" p="20px" h="fit-content" border="1px solid #D1E0FF" borderRadius="12px">
                <Box>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Enter your password<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                </Text>
                                <InputGroup size='md'>
                                    <Input
                                        type={show ? "text" : "password"}
                                        variant="outline"
                                        border="1px solid #F3F4F6"
                                        focusBorderColor={errors.password ? "red.500" : "#D1E0FF"}
                                        bg="white"
                                        placeholder="Please enter your password"
                                        size="md"
                                        value={password}
                                        onChange={(e) => handleChange("password", e.target.value)}
                                        onBlur={() => handleBlur("password")}
                                        borderColor={errors.password ? "red.500" : "#F3F4F6"}
                                        sx={{
                                            '::placeholder': {
                                                color: '#9DA4AE',
                                            },
                                            whiteSpace: 'nowrap',
                                            overflow: 'auto',
                                        }}
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' bg={'white'} _hover={{bg:'white'}} onClick={handleClick}>
                                        {show ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {errors.password && <Text color="red.500" fontSize="sm">{errors.password}</Text>}
                            </Box>
                        </GridItem>
                        <GridItem w="100%" h="fit-content">
                            <Box>
                                <Text fontWeight="medium" fontSize="14px" mb={2}>
                                    Enter your confirm password<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                </Text>
                                <InputGroup size='md'>
                                    <Input
                                        type={showC ? "text" : "password"}
                                        variant="outline"
                                        border="1px solid #F3F4F6"
                                        focusBorderColor={errors.confirmPassword ? "red.500" : "#D1E0FF"}
                                        bg="white"
                                        placeholder="Please enter your confirm password"
                                        size="md"
                                        value={confirmPassword}
                                        onChange={(e) => handleChange("confirmPassword", e.target.value)}
                                        onBlur={() => handleBlur("confirmPassword")}
                                        borderColor={errors.confirmPassword ? "red.500" : "#F3F4F6"}
                                        sx={{
                                            '::placeholder': {
                                                color: '#9DA4AE',
                                            },
                                            whiteSpace: 'nowrap',
                                            overflow: 'auto',
                                        }}
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' bg={'white'} _hover={{bg:'white'}} onClick={handleClickC}>
                                        {showC ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {errors.confirmPassword && <Text color="red.500" fontSize="sm">{errors.confirmPassword}</Text>}
                            </Box>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(6, 1fr)" }} gap={6} mt={6}>
                        <GridItem w="100%" h="fit-content">
                            <Button
                                bg={isFormValid() ? "#2970FF" : "#F04438"}
                                color="white"
                                variant="solid"
                                width="100%"
                                _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
                                onClick={handlePasswordChange}
                                isDisabled={!isFormValid()}
                            >
                                Change Password
                            </Button>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </ChakraProvider>
  );
}

export default Account;
