import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Text,
  Box,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useParams } from "react-router-dom";

// Modal Component
const RemarksModal = ({ isOpen, onClose, headerText, bodyText }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent my={4} p={4}>
      <ModalHeader color="black" fontWeight="700" fontSize="18px" pb={2}>
        {headerText}
      </ModalHeader>
      <ModalBody>
        <Text
          color="#6C737F"
          fontWeight="300"
          fontSize="16px"
          textAlign="justify"
          lineHeight="22px"
        >
          {bodyText}
        </Text>
        <br />
        <Text mb={1} fontWeight="500" fontSize="16px" color="#111927">
          Add Remarks
        </Text>
        <Textarea
          p={2}
          border="1px solid #D1E0FF"
          bg="#F5F8FF"
          borderRadius="10px"
          focusBorderColor="#D1E0FF"
          placeholder="Here is a text"
          size="sm"
          resize="none"
          height="180px"
        />
      </ModalBody>
      <ModalFooter
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="ghost"
          bg="#EFF4FF"
          _hover={{ bg: "#EFF4FF" }}
          color="#2970FF"
          width="47.5%"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          bg="#2970FF"
          color="white"
          variant="solid"
          width="47.5%"
          _hover={{ bg: "#7044c4" }}
        >
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

// Indicator Box Component
const IndicatorBox = ({
  title,
  businessRelevance,
  maturityLevel,
  details,
  remarksHeader,
  remarksBody,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const statusColors = {
    Good: "#12B76A",
    Normal: "#FF692E",
    Excellent: "#2970FF",
  };

  return (
    <Box
      border="1px solid"
      borderColor="#D1E0FF"
      height="fit-content"
      borderRadius="20px"
      mb={4}
    >
      <Box
        p={4}
        borderBottom="1px solid"
        borderColor="#D1E0FF"
        bg="#F5F8FF"
        borderTopRadius="20px"
      >
        <Text color="Black" fontSize="16px" fontWeight="700" textAlign="justify">
          {title}
        </Text>
      </Box>
      <Box
        width="100%"
        borderBottom="1px solid"
        bg="#F5F8FF"
        borderColor="#D1E0FF"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          p={4}
          borderRight="1px solid"
          borderColor="#D1E0FF"
          width="50%"
        >
          <Text
            color="black"
            fontWeight="500"
            fontSize="14px"
            textAlign="justify"
          >
            Business Relevance:
            <Text
              as="span"
              fontWeight="500"
              fontSize="14px"
              color={statusColors[businessRelevance]}
            >
              {" "}
              {businessRelevance}{" "}
            </Text>
          </Text>
        </Box>
        <Box width="50%" p={4}>
          <Text
            color="black"
            fontWeight="500"
            fontSize="14px"
            textAlign="justify"
          >
            Maturity Level:
            <Text
              as="span"
              fontWeight="500"
              fontSize="14px"
              color={statusColors[maturityLevel]}
            >
              {" "}
              {maturityLevel}{" "}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box p={4}>
        <Text
          color="black"
          fontWeight="500"
          fontSize="14px"
          textAlign="justify"
        >
          {details}
        </Text>
      </Box>
      <Box p={4} borderTop="1px solid" borderColor="#D1E0FF">
        <Box
          onClick={onOpen}
          cursor="pointer"
          width="fit-content"
          height="fit-content"
          display="flex"
          justifyItems="flex-start"
          alignItems="center"
        >
          <ChatBubbleOutlineOutlinedIcon
            sx={{ fontSize: 20, color: "#2970FF" }}
          />
          <Text
            pl={1}
            pb={1}
            color="#2970FF"
            fontSize="14px"
            fontWeight="500"
            textAlign="justify"
          >
            Remark
          </Text>
        </Box>
      </Box>
      <RemarksModal
        isOpen={isOpen}
        onClose={onClose}
        headerText={remarksHeader}
        bodyText={remarksBody}
      />
    </Box>
  );
};

function TechIndicator() {
  const { slug } = useParams(); // Get the slug from the URL
  const [indicatorData, setIndicatorData] = useState(null);

  useEffect(() => {
    // Fetch data based on slug
    // Replace this with your actual API endpoint
    fetch(`/api/techIndicators/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setIndicatorData(data);
      })
      .catch((error) => {
        console.error("Error fetching tech indicator data:", error);
      });
  }, [slug]);

  return (
    <ChakraProvider>
      <Box px={10} py={10} w="100%" h="fit-content" overflowY="scroll">
        <Heading fontSize="24px" fontWeight="700" color="black" mb={1}>
          Tech Indicators
        </Heading>
        <br />
        <Box>
          <Box pb={3}>
            <Text fontSize="18px" fontWeight="500" color="black">
              {indicatorData.title}
            </Text>
          </Box>
          {indicatorData.indicators.map((indicator, index) => (
            <IndicatorBox
              key={index}
              title={indicator.title}
              businessRelevance={indicator.businessRelevance}
              maturityLevel={indicator.maturityLevel}
              details={indicator.details}
              remarksHeader={indicator.remarksHeader}
              remarksBody={indicator.remarksBody}
            />
          ))}
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default TechIndicator;
