import React, { useEffect, useState } from 'react';
import { ChakraProvider, Text, Box, Heading, UnorderedList, ListItem, useDisclosure,Icon } from '@chakra-ui/react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { EditIcon } from '@chakra-ui/icons'
import { get } from '../../api';
import AddRemarksModal from './addremarks';
import EditAssertionModal from './editAssertion';
import ViewRemarksModal from './viewremarks';
import useAdminCheck from '../../useAdminCheck';

function KeyFindingItem({ reportId, item, type, refreshData }) {
  const { isOpen:isAddRemarkOpen, onOpen:onAddRemarkOpen, onClose:onAddRemarkClose } = useDisclosure();
  const { isOpen:isEditAssertionOpen, onOpen:onEditAssertionOpen, onClose:onEditAssertionClose } = useDisclosure();
  const { isOpen:isViewRemarkOpen, onOpen:onViewRemarkOpen, onClose:onViewRemarkClose } = useDisclosure();
  const [currentId, setCurrentId] = useState(null);
  const [hasRemarks, setHasRemarks] = useState(false);
  const { isAdmin } = useAdminCheck();

  useEffect(() => {
    if (item.remark && item.remark.length > 0) {
      setHasRemarks(true);
    }
  }, [item]);

  const handleRemarkClick = () => {
    const id = type === 'strength' ? item.keyStrengthId : item.keyFindingId;
    setCurrentId(id);
    onAddRemarkOpen();
  };

  const handleEditAssertionClick = () => {
    const id = type === 'strength' ? item.keyStrengthId : item.keyFindingId;
    setCurrentId(id);
    onEditAssertionOpen();
  };

  const handleViewRemarkClick = () => {
    const id = type === 'strength' ? item.keyStrengthId : item.keyFindingId;
    setCurrentId(id);
    onViewRemarkOpen();
  };

  const handleRemarkResolved = () => {
    setHasRemarks(false);
    refreshData();
  };

  return (
    <Box border='1px solid' borderColor="#D1E0FF" height='fit-content' borderRadius='20px' mb={5}>
      <Box p={4} borderBottom='1px solid' borderColor="#D1E0FF" bg='#F5F8FF' borderTopRadius='20px'>
        <Text color='Black' fontSize="16px" fontWeight='600' textAlign={'justify'}>{item.heading}</Text>
      </Box>
      <Box p={6}>
        <UnorderedList>
          {item.answer.map((point, index) => (
            <ListItem key={index} fontSize="14px" fontWeight="500" color='#3e424a' lineHeight='21px' textAlign={'justify'}>{point}</ListItem>
          ))}
        </UnorderedList>
      </Box>
      <Box p={4} borderTop='1px solid' borderColor="#D1E0FF" display='flex'>
        <Box cursor='pointer' mr={3} width='fit-content' height='fit-content' display='flex' justifyItems={'flex-start'} alignItems={'center'} onClick={handleRemarkClick}>
          <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 20, color: '#2970FF' }} />
          <Text pl={1} pb={1} color='#2970FF' fontSize="14px" fontWeight='500' textAlign={'justify'}>Comments</Text>
        </Box>
        {isAdmin && (<>
          <Box cursor='pointer' width='fit-content' height='fit-content' display='flex' justifyItems={'flex-start'} alignItems={'center'} mr={3} onClick={handleEditAssertionClick}>
              <Icon as={EditIcon} boxSize={4} color='black' cursor='pointer'  />
              <Text pl={1} color='black' fontSize="14px" fontWeight='500' textAlign={'justify'}>Edit</Text>
          </Box>
          {hasRemarks && (
            <Box cursor='pointer' width='fit-content' height='fit-content' display='flex' justifyItems={'flex-start'} alignItems={'center'} onClick={handleViewRemarkClick}>
              <Text pl={1} textDecoration='underline' color='#2970FF' fontSize="13px" fontWeight='500' textAlign={'justify'}>1 Comments</Text>
            </Box>
          )}
        </>)}
      </Box>
      {currentId && (<>
        <AddRemarksModal isOpen={isAddRemarkOpen} onClose={onAddRemarkClose} reportId={reportId} type={type} id={currentId} refreshData={refreshData}/>
        <EditAssertionModal isOpen={isEditAssertionOpen} onClose={onEditAssertionClose} reportId={reportId} type={type} id={currentId} refreshData={refreshData} />
        <ViewRemarksModal isOpen={isViewRemarkOpen} onClose={onViewRemarkClose} reportId={reportId} type={type} id={currentId} refreshData={handleRemarkResolved}/>
      </>)}
    </Box>
  );
}

function AssessmentFindingSection({ sectionTitle, items, reportId, type, refreshData  }) {
  return (
    <Box>
      <Box pb={3}>
        <Text fontSize="18px" fontWeight='600' color="black">{sectionTitle}</Text>
      </Box>
      {items.map((item, index) => (
        <KeyFindingItem
          key={index}
          reportId={reportId}
          item={item}
          type={type}
          refreshData ={refreshData}
        />
      ))}
    </Box>
  );
}

function Keyfinding() {
  const [keyStrengths, setKeyStrengths] = useState([]);
  const [keyFindings, setKeyFindings] = useState([]);
  const reportId = "1"; // You can dynamically set this if needed

  const fetchData = async () => {
    try {
      const response = await get(`report/assessment/${reportId}`);
      setKeyStrengths(response.data.data.assessmentFindings.keyStrength);
      setKeyFindings(response.data.data.assessmentFindings.keyFinding);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reportId]);

  return (
    <ChakraProvider>
      <Box px={10} py={10} w='100%' h='fit-content' overflowY='auto'>
        <Heading fontWeight='700' fontSize='24px' color='black' mb={1}>Assessment Findings</Heading>
        <br />
        <AssessmentFindingSection sectionTitle="Key Strengths" items={keyStrengths} reportId={reportId} type="strength" refreshData={fetchData} />
        <br />
        <AssessmentFindingSection sectionTitle="Key Findings" items={keyFindings} reportId={reportId} type="finding" refreshData={fetchData}/>
      </Box>
    </ChakraProvider>
  );
}

export default Keyfinding;
