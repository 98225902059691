import React, { useState, useEffect } from 'react';
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, TableContainer, Button, Menu, Text, Link, MenuButton, MenuList, MenuItem, 
  MenuDivider, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, 
  Textarea, Spinner, Icon
} from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { CheckIcon } from '@chakra-ui/icons';
import { get, patch, remove } from '../../api';

const muiTheme = createTheme();

const RemarksModal = ({ isOpen, onClose, feedbackId, refreshFeedbackData  }) => {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && feedbackId) {
      const fetchFeedbackDetails = async () => {
        try {
          const response = await get(`feedbacks/${feedbackId}`);
          const feedbackData = response.data.data;
          setSubject(feedbackData.subject);
          setDescription(feedbackData.description);
          setFiles(feedbackData.uploadDocument);
        } catch (error) {
          console.error('Error fetching feedback details:', error);
        }
      };
      fetchFeedbackDetails();
    }
  }, [isOpen, feedbackId]);

  const updateFeedback = async () => {
    const data = {
      status: 'read',
    };
    try {
      await patch(`feedbacks/${feedbackId}`, data);
      refreshFeedbackData ()
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handlePreview = (file) => {
    setLoading(true);
    setPreviewFile(file);
  };

  const handlePreviewClose = () => {
    setPreviewFile(null);
    setLoading(false);
  };

  const closeModal = () => {
    if (previewFile) {
      handlePreviewClose();
    } else {
      onClose();
    }
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent my={4} p={4} height="fit-content" overflow="hidden">
        {previewFile && (
          <>
            <ModalHeader px={2} pt={0} pb={2} fontSize='16px'>File Preview</ModalHeader>
            <ModalCloseButton px={2} py={0} color='#2970FF' onClick={handlePreviewClose} />
          </>
        )}
        <ModalBody height='fit-content' overflowY="auto" px={previewFile ? 2 : 5}>
          {previewFile ? (
            <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
              {loading && <Spinner thickness='2px' size='sm' color='#2970FF' />}
              <img
                src={`${process.env.REACT_APP_BASE_URL}/feedbacks/files/${previewFile.fileId}`}
                alt="File Preview"
                style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%', objectFit: 'contain' }}
                onLoad={handleImageLoad}
              />
            </Box>
          ) : (
            <>
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb={2}>Subject</Text>
                <Textarea
                  px={3}
                  border='1px solid #D1E0FF'
                  focusBorderColor='#2970FF'
                  bg="#F5F8FF"
                  borderRadius='10px'
                  placeholder='Subject'
                  size='md' resize={'none'} fontSize="14px"
                  value={subject}
                  minHeight='fit-content'
                  lineHeight='21px'
                  isReadOnly
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                  css={{
                    '::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none'
                  }}
                />
              </Box>
              <br />
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb={2}>Description</Text>
                <Textarea p={3} value={description} isReadOnly border='1px solid #D1E0FF' textAlign="justify" bg='#F5F8FF' borderRadius='10px' focusBorderColor='#D1E0FF' size='sm' resize={'none'} height='150px' 
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                css={{
                    '::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none'
                }}/>
              </Box>
              <br />
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb='18px'>Attachments</Text>
                {files.map((file, index) => (
                  <Box key={file.fileId} display='flex' mb={3} flexDirection='row' justifyContent='space-between' alignItems='center' width={'100%'}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width={'70%'}>
                      <PhotoOutlinedIcon sx={{ color: '#00359E' }} />
                      <Box ml={2} sx={{ flexGrow: 1, maxWidth: 'calc(100% - 70px)', overflow: 'hidden' }}>
                        <Text color="black" fontWeight="500" fontSize="14px" lineHeight="22px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {file.filename}
                        </Text>
                      </Box>
                      <Box display='flex' alignItems='center' ml={3}>
                        <Text as='span' color='#2970FF' fontWeight='400' fontSize='13px' textAlign='right' lineHeight='22px' mr={1}>•</Text>
                        <Link onClick={() => handlePreview(file)} color='#2970FF' style={{ textDecoration: 'underline' }}  _hover={{ textDecorationColor: '#2970FF' }}>
                          <Text color='#2970FF' fontWeight='400' fontSize='13px' textAlign='right' lineHeight='22px'>Preview</Text>
                        </Link>
                      </Box>
                    </Box>
                    <Text color='#00359E' fontWeight='400' fontSize='12px' textAlign='justify' lineHeight='22px'>{(file.size / (1024 * 1024)).toFixed(2)} MB</Text>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </ModalBody>
        {!previewFile && (
          <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
            <Button bg="#2970FF" color='white' variant='solid' width='47.5%' _hover={{ bg: '#7044c4' }} onClick={() => { onClose(); updateFeedback(); }}>
              Done
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent borderRadius="10px">
      <ModalBody px={5} pt={5}>
        <Box display="flex" alignItems="center">
          <Box mr={3} sx={{ backgroundColor: '#FEE4E2', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '45px', height: '45px' }}>
            <Icon as={WarningAmberRoundedIcon} w="30px" height="30px" sx={{ color: '#F04438' }} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="18px">Delete</Text>
            <Text fontSize='14px' fontWeight='500' color='#6C737F'>Are you sure you want to delete Feedback?</Text>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter >
        <Button variant='ghost' bg='white' color='black' onClick={onClose}>Cancel</Button>
        <Button colorScheme="red" onClick={onConfirm} ml={3}>Confirm</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const PopoverMenu = ({feedbackId, refreshFeedbackData , feedbackStatus }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const updateFeedback = async (status) =>{
    const data = {
      status: status,
    };
    try {
      const response = await patch(`feedbacks/${feedbackId}`, data);
      // Show success toast
      toast({
        title: response.data.message,
        description: 'Feedback has been updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      refreshFeedbackData()   
    } catch (error) {
        console.error('Error submitting form:', error);
        if(error.response.status === 400)
        {
          // Show error toast
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
        else if(error.response.status === 404){
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
    }
  }

  const deleteFeedback = async () =>{


    try {
      const response = await remove(`feedbacks/${feedbackId}`);
      // Show success toast
      toast({
        title: response.data.message,
        description: 'Feedback has been deleted successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      // const feedbackResponse = await get(`feedbacks/feedback/list`, {
      //   params: {
      //     page: currentPage,
      //     limit: 10,
      //   }
      // });
      // if (feedbackResponse.data.data.length === 0 && currentPage > 1) {
      //   setCurrentPage(currentPage - 1);
      //   refreshFeedbackData(currentPage - 1);
      // } else {
        refreshFeedbackData();
      // }
    } catch (error) {
        console.error('Error submitting form:', error);
        if(error.response.status === 400)
        {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
        else if(error.response.status === 404){
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
    }
  }

  const handleDeleteConfirm = () => {
    deleteFeedback();
    onDeleteClose();
  }

    return (<>
        <Menu>
            <MenuButton as={Button}
                bg="white"
                color="black"
                _hover={{ bg: "white" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
            >
                <MoreVertRoundedIcon />
            </MenuButton>
            <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
              <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={onOpen}>View Feedback
              <RemarksModal isOpen={isOpen} onClose={onClose} feedbackId={feedbackId} refreshFeedbackData ={refreshFeedbackData}/> </MenuItem>
              <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
              { feedbackStatus !== 'read' && ( <>
                <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => updateFeedback('read')}  >Mark as Read</MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
              </>)}
              { feedbackStatus !== 'unread' && ( <>
                <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => updateFeedback('unread')}>Mark as Unread</MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
              </>)}
              <MenuItem borderRadius='5px'bg="white" _hover={{ bg: "#FEF3F2" }} color='#F04438' onClick={onDeleteOpen}>Delete Feedback  </MenuItem>
            </MenuList>
        </Menu>
        <DeleteConfirmationModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onConfirm={handleDeleteConfirm}
        />
      </>
    );
  };

  function FeedbackTableComponent({ searchTerm }) {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [feedbackData, setFeedbackData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('latest');
    const [sortBy, setSortBy] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');
    const [status, setStatus] = useState('');

    const statusColors = {
      "read": "#12B76A",
      "unread": "#FF692E",
    };
  
    const fetchUserDetails = async (page, searchTerm, sortBy, order, status) => {
      try {
        const response = await get(`feedbacks/feedback/list`, {
          params: {
            page: page,
            limit: pageSize,
            query: searchTerm,
            sortBy: sortBy,
            sortOrder: order,
            sortStatus: status
          }
        });
        setFeedbackData(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching feedback list:', error);
      }
    };

    const refreshFeedbackData = () => {
      fetchUserDetails(currentPage, searchTerm, sortBy, sortOrder, status);
    };

    useEffect(() => {
      setCurrentPage(1);
    }, [searchTerm]);
  
    useEffect(() => {
      fetchUserDetails(currentPage, searchTerm, sortBy, sortOrder, status);
    }, [currentPage, searchTerm]);

    const handleChangePage = (event, newPage) => {
      setCurrentPage(newPage);
      fetchUserDetails(newPage, searchTerm, sortBy, sortOrder, status);
    };
  
    useEffect(() => {
      setCurrentPage(1);
      fetchUserDetails(1, searchTerm, sortBy, sortOrder, status);
    }, [searchTerm, sortBy, sortOrder, status]);
  
    const handleFilterChange = (filterType, sortBy, order, status) => {
      if (selectedFilter === filterType) {
        setSelectedFilter('');
        setSortBy('');
        setSortOrder('');
        setStatus('');
        fetchUserDetails(currentPage, searchTerm, '', '', '');
      } else {
        setSelectedFilter(filterType);
        setSortBy(sortBy);
        setSortOrder(order);
        setStatus(status);
        fetchUserDetails(currentPage, searchTerm, sortBy, order, status);
      }
    };
  
    return (
      <ChakraProvider>
        <Box width="100%" py={4}>
          <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th py={4} borderColor="#D1E0FF" colSpan={5} color='black' fontSize={'16px'} backgroundColor={'#F5F8FF'} textTransform="none">Feedback</Th>
                  <Th py={3} borderColor="#D1E0FF" backgroundColor={'#F5F8FF'} display={'flex'} justifyContent={'flex-end'}>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<FilterListOutlinedIcon />}
                        bg="#D1E0FF"
                        color="black"
                        _hover={{ bg: "#c5d7fc" }}
                        _active={{ bg: "#c5d7fc" }}
                        _focus={{ bg: "#c5d7fc" }}
                        textAlign="right"
                        h="38px"
                      >
                        Sort
                      </MenuButton>
                      <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                        <MenuItem
                          borderRadius='5px'
                          bg={selectedFilter === 'latest' ? "#EFF4FF" : "white"}
                          _hover={{ bg: "#EFF4FF" }}
                          color='#00395E'
                          onClick={() => handleFilterChange('latest', 'createdAt', 'desc','')}
                        >
                          Latest First
                          {selectedFilter === 'latest' && (
                            <Box as="span" color="#00359E" ml={2}>
                              <CheckIcon boxSize={3} />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                        <MenuItem
                          borderRadius='5px'
                          bg={selectedFilter === 'unread' ? "#EFF4FF" : "white"}
                          _hover={{ bg: "#EFF4FF" }}
                          color='#00395E'
                          onClick={() => handleFilterChange('unread', 'status', 'desc', 'unread')}
                        >
                          Unread First
                          {selectedFilter === 'unread' && (
                            <Box as="span" color="#00359E" ml={2}>
                              <CheckIcon boxSize={3} />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                        <MenuItem
                          borderRadius='5px'
                          bg={selectedFilter === 'read' ? "#EFF4FF" : "white"}
                          _hover={{ bg: "#EFF4FF" }}
                          color='#00395E'
                          onClick={() => handleFilterChange('read', 'status', 'desc', 'read')}
                        >
                          Read First
                          {selectedFilter === 'read' && (
                            <Box as="span" color="#00359E" ml={2}>
                              <CheckIcon boxSize={3} />
                            </Box>
                          )}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Th>
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  <Th py={4} fontSize={'14px'} borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">User Name</Th>
                  <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">Email Address</Th>
                  <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">Company Name</Th>
                  <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='50%' whiteSpace="normal" textTransform="none">
                    Feedback
                    <style jsx>{`
                      .feedback-text {
                        max-height: 3em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                      }
                    `}</style>
                  </Th>
                  <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='10%' whiteSpace="normal" textTransform="none">Status</Th>
                  <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='10%' textTransform="none">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {feedbackData.map(item => (
                  <Tr key={item.feedbackId}>
                    <Td borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">{item.userName}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">
                      <a href={`mailto:${item.emailId}`} style={{ textDecoration: 'none', color: '#6C737F' }}  onMouseOver={(e) => e.currentTarget.style.color = '#00359E'} onMouseOut={(e) => e.currentTarget.style.color = '#6C737F'}>
                        <div className="email-text">{item.emailId}</div>
                      </a>
                    </Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">{item.companyName}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='50%' whiteSpace="normal">
                      <div className="feedback-text">{item.description}</div>
                    </Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color={statusColors[item.status]} width='10%'>{item.status === 'read' ? 'Read' : 'Unread'}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" width='10%'><PopoverMenu feedbackId={item.feedbackId} feedbackStatus={item.status} refreshFeedbackData={refreshFeedbackData} /></Td>
                  </Tr>
                ))}
                <Tr>
                  <Th colSpan={7} verticalAlign="middle" textAlign={'center'} backgroundColor={'#F5F8FF'}>
                    <ThemeProvider theme={muiTheme}>
                      <Box w='100%' display="flex" justifyContent="center" alignItems="center">
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            '& .MuiPaginationItem-root': {
                              borderColor: '#D1E0FF',
                              backgroundColor: 'white',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              },
                              '&.Mui-selected': {
                                backgroundColor: 'white',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              }
                            },
                            '& .MuiPaginationItem-previousNext': {
                              backgroundColor: '#F5F8FF',
                              borderColor: '#D1E0FF',
                              color: '#9E9E9E',
                              '&:hover': {
                                backgroundColor: '#F5F8FF',
                                borderColor: '#2970FF',
                                color: '#2970FF',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F5F8FF',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              },
                              '&.Mui-disabled': {
                                backgroundColor: '#6C737F',
                                color: '#EEEEEE',
                                borderColor: '#6C737F',
                              }
                            }
                          }}
                        />
                      </Box>
                    </ThemeProvider>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </ChakraProvider>
    );
  }

export default FeedbackTableComponent;
