import '../../App.css';
import React from "react";
import { ChakraProvider, Text, Box, Heading, Input, Textarea, Button, Icon, useToast } from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import { post } from '../../api';
import { ReactComponent as circleCloseIcon } from '../../icons/circleCloseIcon.svg';
import useAdminCheck from '../../useAdminCheck';

function Feedback() {
  const toast = useToast();
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [file, setFile] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const { userId } = useAdminCheck();
  const fileInputRef = React.useRef(null);

  const handleFileChange = (event) => {
    setFile(Array.from(event.target.files));
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const removeFile = (filename) => {
    setFile(file.filter(file => file.name !== filename));
  };

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'subject':
        if (!value) {
          error = 'Subject is required';
        }
        break;
      case 'description':
        if (!value) {
          error = 'Description is required';
        }
        break;
      case 'file':
        if (file.length === 0) {
          error = 'At least one image file is required';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async () => {
    const fields = ['subject', 'description', 'file'];

    const validationErrors = {};

    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append('subject', subject);
    data.append('description', description);
    file.forEach((file) => data.append('uploadDocument', file));
    try {
      const response = await post(`feedbacks/${userId}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
      toast({
        title: 'Feedback has been created successfully',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setSubject('');
      setDescription('');
      setFile([]);
      fileInputRef.current.value = null;
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const isFormInvalid = () => {
    return !subject || !description || file.length === 0 || Object.keys(errors).some((key) => errors[key]);
  };

  return (
    <ChakraProvider>
        <Box display='flex' flexDirection='column' height='100%'>
        <Box flex='1' overflowY='auto' display='flex' flexDirection='column' justifyContent='space-between' w="100%" h='fit-content'>
          <Box px={[4, 6, 10]} py={10}>
          <Box>
            <Heading size='md' fontSize='24px' fontWeight='700' mb={4}>Feedback</Heading>
          </Box>
          <Box w='100%' bg='#F5F8FF' p={[4, 6, 8]} border='1px solid #D1E0FF' borderRadius='12px'>
            <Text fontWeight="medium" fontSize='14px' mb={2}>Subject of the feedback<span style={{ color: 'red', marginLeft: '4px' }}>*</span></Text>
            <Box mb={5}>
              <Input
                variant='outline'
                border='1px solid'
                focusBorderColor='#D1E0FF'
                bg="white"
                placeholder='Write the subject of the feedback here'
                size='md'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                onBlur={handleBlur('subject')}
                borderColor={errors.subject ? 'red.500' : '#F3F4F6'}
                sx={{
                  '::placeholder': {
                    color: '#9DA4AE',
                  },
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                }}
              />
              {errors.subject && <Text color="red.500" fontSize="sm">{errors.subject}</Text>}
            </Box>
            <Text fontWeight="medium" fontSize='14px' mb={2}>Description<span style={{ color: 'red', marginLeft: '4px' }}>*</span></Text>
            <Box mb={5}>
              <Textarea
                px={3}
                variant='outline'
                border='1px solid #F3F4F6'
                focusBorderColor='#D1E0FF'
                bg="white"
                placeholder='Write the decription of the feedback here'
                size='md'
                resize='none'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={handleBlur('description')}
                borderColor={errors.description ? 'red.500' : '#F3F4F6'}
                sx={{
                  '::placeholder': {
                    color: '#9DA4AE',
                  },
                }}
                css={{
                  '::-webkit-scrollbar': { display: 'none' },
                  '-ms-overflow-style': 'none',
                  'scrollbar-width': 'none'
                }}
              />
              {errors.description && <Text color="red.500" fontSize="sm">{errors.description}</Text>}
            </Box>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              hidden
              multiple
              accept=".jpeg, .png, .jpg, .gif"
              onChange={handleFileChange}
            />
            <Button leftIcon={<AttachmentIcon />} variant='outline' bg="white" borderColor='#2970FF' color='#2970FF' _hover={{ bg: 'white' }} mb={3} onClick={handleFileButtonClick}>
              Attach file
            </Button>
            {file.length > 0 && (
              <Box display='flex' flexDirection='column' mb={2}>
                <Text fontSize='14px' style={{ color: '#111927', fontWeight: '300' }} pr={2}>Attached Files</Text>
                <Box p={3} display='flex' flexWrap='wrap' gap={2} maxWidth='100%'>
                  {file.map((item, index) => (
                    <Box key={index} display='flex' alignItems='center' py='3px' px='10px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' border='1px solid #D1E0FF' borderRadius='5px' bg='#D1E0FF'>
                      <Text fontSize='12px' style={{ color: '#004EEB', fontWeight: '400' }} pr='2'>{item.name}</Text>
                      <Icon as={circleCloseIcon} w="16px" h="16px" sx={{ cursor: 'pointer' }} onClick={() => removeFile(item.name)}/>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Text fontSize='14px' color='gray' style={{ color: '#6C737F', fontWeight: '500' }}>Note: Please include your attachments in .jpeg or .png format to provide clearer explanation of your concern</Text>
          </Box>
        </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' py={4} px={5} w='100%' bg='white' borderTop='1px solid #D1E0FF'>
          <Button
            bg={!isFormInvalid() ? "#2970FF" : "#F04438"}
            color='white'
            variant='solid'
            width={{ base: 'fit-content', md: '15%', lg: '18%', xl: '15%' }}
            _hover={{ bg: !isFormInvalid() ? '#7044c4' : '#F04438' }}
            onClick={handleSubmit}
            isDisabled={isFormInvalid()}
            maxW="100%"
          >
            Send Feedback
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Feedback;
